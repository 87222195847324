<template>
  <CardConf
    :title="$tc('service', 2)"
    :icon="'$reservation_sistem'"
    size="530"
    :activeted="true"
    ref="appointments"
    :card_active="card_active"
  >
    <template>
      <v-row>
        <v-spacer />
        <AddService
          @update="fetchService"
          :already_services="services"
        ></AddService>
      </v-row>
      <v-row justify="center">
        <template v-for="(s, i) in services">
          <v-col cols="4" :key="`col-${i}`" class="mx-2">
            <SubServicesList :service="s" @update="fetchService" />
          </v-col>
        </template>
        <v-col cols="4" :key="`col-${i}`" class="mx-2">
          <PackSubServices />
        </v-col>
      </v-row>
    </template>
  </CardConf>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ServicesCard",
  components: {
    CardConf: () => import("@/components/user/settings/Card"),
    LaborExange: () =>
      import(
        "@/components/user/settings/configuracion/appointments/LaborExangeDialog"
      ),
    States: () =>
      import("@/components/user/settings/configuracion/appointments/States"),
    PolicyLink: () => import("@/components/user/settings/profile/PolicyLink"),
    PolicyEditor: () =>
      import("@/components/user/settings/profile/PolicyEditor"),
    Marketplaces: () =>
      import("@/components/user/settings/configuracion/Marketplaces"),
    ClientInfo: () =>
      import(
        "@/components/user/settings/configuracion/appointments/ClientInfo"
      ),
    SubServicesList: () =>
      import(
        "@/components/user/settings/configuracion/subservices/SubServicesList"
      ),
    AddService: () =>
      import("@/components/user/settings/configuracion/services/Add"),
    PackSubServices: () =>
      import("@/components/user/settings/configuracion/packs/PackSubServices"),
  },
  props: {
    card_active: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    ...mapActions("services", ["getServices"]),
    fetchService() {
      this.getServices({}).then((response) => {
        this.services = response.data;
      });
    },
  },
  mounted() {
    this.fetchService();
  },
  data() {
    return {
      disabled: false,
      services: [],
      link: null,
      dialog_general: false,
      dialog_services: false,
      dialog_appointment_list: false,
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
      itemsServices: [],
      dialog_form: false,
      policy_items: [
        { text: this.$t("policys.link"), value: "link" },
        { text: this.$t("policys.editor"), value: "page" },
      ],
    };
  },
};
</script>
